@import "../theme/index";

.help-info {
  background-color: $grey;
}

.help-info-line{
  width: 100%;
  padding: 0 6% 20px 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $blackApprox;
  justify-content: space-between;
  @include respond-to(desktop) {
    align-items: normal;
    flex-direction: row;
  }

  &__column{
    width: 90%;
    @include respond-to(tablets) {
      width: 60%;
    }
    @include respond-to(desktop) {
      width: 30%;
    }

    &__side{
      margin-top: 32px;
      @include respond-to(desktop) {
        margin-top: 40px;
      }
      @include respond-to(largedesktop) {
        margin-top: 60px;
        width: 344px;
      }
    }

    &__center{
      @include respond-to(desktop) {
        margin-top: 40px;
      }
      @include respond-to(largedesktop) {
        margin-top: 60px;
        width: 389px;
      }
    }
  }

  &__title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    @include respond-to(desktop) {
      text-align: start;
      font-size: 26px;
    }
    @include respond-to(largedesktop) {
      font-size: 36px;
    }
  }

  &__description {
    text-align: center;
    font-size: 13px;
    text-decoration: underline;
    margin-top: 22px;
    margin-bottom: 32px;
    @include respond-to(desktop) {
      text-align: start;
      font-size: 20px;
    }
    @include respond-to(largedesktop) {
      font-size: 24px;
      margin-top: 32px;
      margin-bottom: 52px;
    }
  }

  &__picture{
    display: flex;
    margin-left: auto;
    margin-right:  auto;
  }

  &__picture-talk{
    width: 174px;
    height: 311px;
    @include respond-to(desktop) {
      margin-top: -34px;
      width: 193px;
      height: 345px;
    }
    @include respond-to(largedesktop) {
      width: 215px;
      height: 380px;
    }

  }

  &__picture-read {
    width: 280px;
    max-height: 234px;
    @include respond-to(desktop) {
      width: 100%;
      max-height: 308px;
    }
  }

  &__picture-write {
    width: 226px;
    height: 236px;
    margin-bottom: 38px;
    @include respond-to(largedesktop) {
      width: 298px;
      height: 311px;
      margin-bottom: 58px;
    }
  }
}

.feedback-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
  padding: 5px 0;
  
  &:hover {
    color: $blue;
  }
}
