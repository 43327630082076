@use "sass:color";
@import "../../components/theme/index";

.journal-form {
  &__modal {
    padding-bottom: 10px;
    min-height: 40%;
    height: auto;
    max-height: 80%;

    @include respond-to(desktop) {
      max-width: 970px;
      margin: 0 auto;
    }
    @include respond-to(widedesktop) {
      max-height: 970px;
    }

    .modal {
      &__header {
        padding: 10px;
        display: flex;
        justify-content: flex-end;
      }

      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 10px;
        position: relative;
      }

      &__close-btn {
        border: none;
        background-color: transparent;
        font-size: 24px;
        padding: 0.5em;
        cursor: pointer;

        &:hover {
          background-color: color.adjust($grey, $lightness: -6%);
        }
      }
    }
  }

  &__container {
    max-width: 700px;
    width: 100%;
  }

  &__title {
    text-align: center;
    white-space: pre-line;
  }

  &__image {
    height: 80%;

    &-wrapper {
      height: 300px;
      display: flex;
      justify-content: center;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    max-width: 690px;
    margin: 0 auto;
    padding: 10px;
  }

  &__button.gritx-btn {
    @include textSize();
    & {
      line-height: 1em;
      min-height: 3em;
      max-width: 20%;
      width: 311px;
    }
  }
}

.form {
  &-journal {
    max-height: 80%;

    &__header {
      margin-bottom: 10px;
      @include respond-to(desktop) {
        font-size: 20px;
      }
    }

    &__title {
      text-align: center;
      margin: 0 0 10px;
      font-size: 24px;
      @include respond-to(desktop) {
        font-size: 2vw;
      }
      @include respond-to(fullHD) {
        font-size: 36px;
      }
    }

    &__subtitle {
      text-align: center;
      font-weight: bold;
      font-size: 18px;
      @include respond-to(desktop) {
        font-size: 1.5vw;
      }
      @include respond-to(fullHD) {
        font-size: 24px;
      }
    }

    &__body {
      overflow: auto;
    }

    &__preview {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;

      &-wrapper {
        position: relative;
        max-height: 170px;
        margin-right: 20px;
        margin-bottom: 20px;
      }
    }
  }
}