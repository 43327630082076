@import "../../components/theme/index";

.journals {
  &__header {
    margin-top: 20px;
    display: flex;
    background-color: $grey;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    @include respond-to(tablets) {
      flex-direction: row;
    }
    @include respond-to(desktop) {
      padding: 0 50px;
      justify-content: space-between;
    }

    &::before {
      content: ' ';
      background-color: $grey;
      position: absolute;
      height: 100%;
      width: 100vw;
      right: 100%;
      top: 0;
    }

    &-sort {

      & fieldset {
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & label {
          padding: 10px;
          font-size: 20px;
          color: black;
        }
      }
    }

  }

  &__button.gritx-btn {
    width: 245px;
    margin-bottom: 10px;
    font-size: 16px;
    height: 60px;
    line-height: initial;
    border: 1px solid $alto;
    font-weight: bold;
    @include respond-to(desktop) {
      margin-bottom: 0;
    }
    @include respond-to(largedesktop) {
      font-size: 24px;
    }

    &-outline {
      background-color: $white;
    }
  }

  &__title {
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    color: $darkGrey;
    margin: 20px 10px 10px;
    text-transform: uppercase;

    @include respond-to(tablets) {
      margin: 0 0 44px 51px;
    }
  }

  &__icon {
    width: 36px;
    height: 33px;
    margin-right: 6px;
  }

  &__image {
    max-height: 200px;
    margin-bottom: 60px;

    @include respond-to(tablets) {
      max-height: 300px;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
  }
}