@import "../theme/index";

.mobile-view {
  min-height: 100vh;

  .apps {
    background-color: $lightGreen;
    min-height: 100vh;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-text {
        font-size: 36px;
        font-weight: bold;
        color: $red;
        text-transform: uppercase;
        padding-bottom: 20px;
      }
    }

    &__link {
      font-size: 24px;
      display: block;
      color: $black;
      text-align: center;
      font-weight: bold;
      width: 160px;

      &:visited {
        color: $black;
      }
    }

    &__store {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__qr {
      width: 160px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;

      &-logo {
        width: 57px;
        height: 68px;
      }

      &-code {
        width: 69px;
        height: 69px;
        transition: all 0.3s;

        &:hover {
          transition: all 0.3s;
          cursor: pointer;
          position: absolute;
          width: 100%;
          right: 0;
        }

        &-image {
          width: 100%;
          height: auto;
        }
      }

      &-image {
        width: 70px;
        height: 70px;
      }

    }

    &__button {
      width: 160px;

      &:hover {
        cursor: pointer;
      }

      &-image {
        width: 100%;
      }

      &--link {
        margin: 50px 0;
      }
    }
  }
}