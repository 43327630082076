@import "../../components/theme/index";

.section {
  &-header {
    &__title {
      font-size: 1.3vw;
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }

  &-body {
    font-size: 1vw;
    margin-bottom: 50px;

    &-text {
      line-height: 1.5em;
      & p {
        padding-bottom: 5px;
      }
    }
  }
}