$grey: #f2f2f2;
$semiDarkGrey: #bcc7c4;
$blackApprox: #020202;
$pigeonPost: #a3c2d6;
$black: #000;
$darkGrey: #303030;
$white: #fff;
$red: #fc767d;
$darkRed: #f8454e;
$green: #8dd2a3;
$lineGreen: #8ecba0;
$lightGreen: #e9f5eb;
$blue: #a4c7dd;
$yellow: #FFD569;
$alto: #d2d2d2;
$doveGray: #676666;
$lightGray: #707070;
$sirocco: #6f7c7d;
$silver: #c7c7c7;
