@import "../../components/theme/index";

.review {
  position: relative;
  min-height:400px;

  &__title {
    display: flex;
    align-items: center;
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    color: $darkGrey;
    margin: 20px 10px 10px;
    text-transform: uppercase;

    @include respond-to(tablets) {
      margin: 0 0 44px 51px;
    }
  }

  &__title-dialogue-name {
    @include respond-to(mobile) {
      display: none;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 6px;
    border: solid 2px $alto;
    border-radius: 50%;
    padding: 7px;
  }

  & .breadcrumbs {
    padding: 0 10px;
    font-size: 24px;
    color: $darkGrey;
    margin: 0;
    @include respond-to(desktop) {
      font-size: 2.5vw;
      padding: 0 25px;
    }
    @include respond-to(fullHD) {
      font-size: 48px;
    }

    &__previous {
      text-transform: uppercase;
      font-weight: bold;
      color: $darkGrey;

      &:visited {
        color: $darkGrey;
      }

      &:hover {
        color: $blue;
      }
    }
  }
}

