@import "components/theme/index";

.file-uploader {
  margin-bottom: 20px;
  &__title{
    @include textSize;
  }
  &__error {
    font-size: 18px;
    color: $darkRed;
  }
  &__button{
    background-color: $semiDarkGrey;
    padding: 10px 20px;
    color: $darkGrey;
    cursor: pointer;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: $white;
  border-style: dashed;
  background-color: transparent;
  transition: border .24s ease-in-out;

  &--active {
    border-color: $blue;
  }

  &--accept {
    border-color: $green;
  }

  &--reject {
    border-color: $darkRed;
  }
}