@import "../theme/index";

.bots {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 17px;
  border: none;
  background: transparent;
  position: relative;

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__avatars {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    @include respond-to(tablets) {
      width: 50%;
      padding: 17px;
      margin: 0;
      border: none;
    }
    @include respond-to(desktop) {
      width: 33.3%;
    }

    &__item {
      cursor: pointer;
    }
  }

  &__name {
    font-size: 18px;
    font-weight: bolder;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
  }

  &__item {
    width: 163px;
    height: 163px;
    padding: 10px 0 0;
    border: none;
    background: transparent;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &__img {
    width: 100%;
    height: 100%;
    max-width: 140px;
    max-height: 140px;
  }

  &__btn {
    position: absolute;
    bottom: -10px;
    right: 15px;
    width: 44px;
    height: 44px;
    border: none;
    background: $lightGreen;

    &:hover {
      cursor: pointer;
    }

    &-icon {
      fill: $green;
    }
  }
}
