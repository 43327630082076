@import "../theme/index";

.privacy{
  margin: 10px 15px 10px 15px;
  color: $black;
  @include respond-to(tablets) {
    margin: 15px 20px 20px 20px;
  }
  @include respond-to(desktop) {
    margin: 20px 48px 20px 48px;
  }
  @include respond-to(largedesktop) {
    margin: 28px 68px 48px 68px;
  }

  &__title-window{
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    margin-bottom: 15px;
    @include respond-to(tablets) {
      font-size: 25px;
      margin-bottom: 20px;
    }
    @include respond-to(desktop) {
      font-size: 38px;
      margin-bottom: 30px;
    }
    @include respond-to(largedesktop) {
      font-size: 48px;
      margin-bottom: 46px;
    }
  }

  &__contents{
    text-align: start;
    font-size: 13px;
    font-weight: bold;
    @include respond-to(tablets) {
      font-size: 18px;
    }
    @include respond-to(desktop) {
      font-size: 25px;
    }
    @include respond-to(largedesktop) {
      font-size: 30px;
    }
  }

  &__description{
    font-size: 11px;
    margin-top: 10px;
    text-align: start;
    @include respond-to(tablets) {
      font-size: 13px;
      margin-top: 18px;
    }
    @include respond-to(desktop) {
      font-size: 20px;
      margin-top: 25px;
    }
    @include respond-to(largedesktop) {
      font-size: 24px;
      margin-top: 30px;
    }
  }

  &__title{
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 0;
    text-align: start;
    @include respond-to(tablets) {
      font-size: 18px;
    }
    @include respond-to(desktop) {
      font-size: 25px;
    }
    @include respond-to(largedesktop) {
      font-size: 30px;
      margin-top: 50px;
    }
  }

  &__list{
    font-size: 11px;
    margin-top: 11px;
    text-align: start;
    @include respond-to(tablets) {
      font-size: 13px;
      margin-top: 13px;
    }
    @include respond-to(desktop) {
      font-size: 20px;
      margin-top: 20px;
    }
    @include respond-to(largedesktop) {
      font-size: 24px;
      margin-top: 24px;
    }
    &__item{
      display: flex;
      flex-direction: row;

      &__description{
        margin-left: 5px;
        color: $pigeonPost;
      }

      &__description-black{
        margin-left: 5px;
      }
    }
  }
}