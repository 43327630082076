@import "../../theme/index";

.email-box {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: $darkGrey;
  border-radius: 15px;
  height: 38px;
  padding: 0 10px 0 16px;
  border: 1px solid $alto;
  margin-right: 15px;
  margin-top: 14px;
  min-width: 10px;

  @include respond-to(tablets) {
    font-size: 24px;
  }
  
  &__delete-btn {
    border: none;
    padding: 0;
    background: none;
    margin-left: 22px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}