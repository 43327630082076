@import "../../components/theme/index";

.input-radio {
  margin-left: 20px;

  &__wrap {
    display: flex;
    flex-direction: row;
  }

  &__label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    position: relative;
    padding-left: 50px;
    margin: 14px 0;
    min-height: 26px;
    @include respond-to(mobile) {
      font-size: 18px;
    }
    &:first-child {
      padding-left: 0;
    }
  }
  &__label-disabled {
    display: flex;
    align-items: center;
    font-size: 20px;
    position: relative;
    padding-left: 50px;
    margin: 14px 0;
    min-height: 26px;
  }

  &__field {
    margin-right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  &__field-disabled {
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }

  [type="radio"] {
    display: none;

    +label {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 26px;
        height: 26px;
        border-radius: 100%;
        border: solid 1px $lightGray;
      }

      &:after {
        content: '';
        position: absolute;
        width: 26px;
        height: 26px;
        background-color: $blue;
        top: 1px;
        left: 1px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
      }
    }

    &:checked + label:before {
      background: $lightGray;
      transition-delay: 0.2s;
    }

    &:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }

    &:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

}
