@import "../../theme/index";

.xpedition-list {

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 41px 100px;
    @include respond-to(mobile) {
      padding: 0;
    }
    @include respond-to(largedesktop) {
      flex-wrap: nowrap;
    }
  }

  &__chat {
    margin: 5px 15px 20px;
    width: 100%;
    position: relative;
    @include respond-to(mobile) {
      margin: 0;
    }
    @include respond-to(largedesktop) {
      width: 50%;
    }
    @include respond-to(mobile) {
      display: none;
    }
  }

  &__chat-toolbar {
    background-color: $grey;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }

  &__bot-avatar {
    width: 40px;
    height: 38px;
    fill: $red;
  }

  &__list {
    margin: 5px 15px 20px;
    width: 100%;
    position: relative;
    @include respond-to(mobile) {
      margin: 0;
    }
    @include respond-to(largedesktop) {
      width: 50%;
    }
  }

  &__chat-wrapper {
    height: 440px;
    padding-bottom: 10px;
    margin-bottom: 18px;

    @include respond-to(tablets) {
      height: 500px;
    }
  }

  &__start-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    height: 100%;
  }


}


//&__wrapper {
//  display: flex;
//  flex-wrap: wrap;
//  justify-content: space-between;
//
//  @include respond-to(tablets) {
//    padding: 0 41px 100px;
//  }
//
//  @include respond-to(largedesktop) {
//    flex-wrap: nowrap;
//  }
//}

//&__window,
//&__content {
//  margin: 5px 15px 20px;
//  width: 100%;
//  position: relative;
//
//  .article {
//    margin-top: 0;
//
//    &__heading {
//      padding: 0 40px;
//      font-size: 35px;
//    }
//  }
//  @include respond-to(largedesktop) {
//    width: 50%;
//  }
//}


//.bot-chat {
//  &__toolbar {
//    background-color: $grey;
//    height: 60px;
//    padding: 0 20px;
//    display: flex;
//    align-items: center;
//  }
//
//  &__title {
//    font-weight: bold;
//    font-size: 18px;
//    @include respond-to(tablets) {
//      font-size: 24px;
//    }
//  }
//
//  &__wrapper {
//    height: 440px;
//    padding-bottom: 10px;
//    margin-bottom: 18px;
//
//    @include respond-to(tablets) {
//      height: 500px;
//    }
//  }
//
//  &__log {
//    display: flex;
//    flex-direction: column;
//    overflow-x: hidden;
//    overflow-y: auto;
//    padding-top: 10px;
//  }
//
//  &__message {
//    position: relative;
//    padding: 6px 0;
//    opacity: 0;
//    transition: all 0.15s ease-in-out;
//    animation: fadeNewMessage 0.7s;
//    animation-fill-mode: forwards;
//  }
//
//  &__message-url {
//    color: $black;
//  }
//
//  &__picture {
//    min-width: 170px;
//    min-height: 170px;
//    max-width: 100%;
//  }
//
//  &__picture-wrapper {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//  }
//
//  &__picture-loader {
//    position: relative;
//    width: 170px;
//    height: 170px;
//    padding: 40px;
//    background: transparent;
//  }
//
//  &__overlay-wrapper {
//    padding: 0 20px;
//  }
//
//  &__message-value {
//    font-size: 20px;
//    line-height: 26px;
//    max-width: 90%;
//    padding: 1rem;
//    min-height: 40px;
//    overflow: hidden;
//    text-overflow: ellipsis;
//    white-space: pre-line;
//
//    @include respond-to(tablets) {
//      max-width: 80%;
//    }
//
//    &:before {
//      top: 36px;
//      border: solid transparent;
//      content: " ";
//      height: 0;
//      width: 0;
//      position: absolute;
//      pointer-events: none;
//      border-width: 12px;
//      margin-top: -12px;
//    }
//  }
//
//  &__group {
//    display: flex;
//    flex-direction: column;
//  }
//
//  &__message-buttons {
//    display: flex;
//    flex-wrap: wrap;
//    margin-top: 8px;
//
//    &:empty {
//      margin-top: 0;
//    }
//  }
//
//  &__chat-button {
//    &--link {
//      background-color: $white;
//      border: solid 1px $alto;
//      font-weight: bold;
//      min-height: 40px;
//      line-height: 38px;
//      padding: 0 10px;
//      color: $blackApprox;
//      text-decoration: none;
//      &:hover{
//        background-color: $grey;
//      }
//    }
//
//    &.gritx-btn {
//      width: auto;
//      font-size: 16px;
//      overflow: hidden;
//      text-overflow: ellipsis;
//      margin: 2px;
//      text-align: center;
//      padding: 0 10px;
//      width: auto;
//      flex-grow: 1;
//
//
//      @include respond-to(tablets) {
//        font-size: 18px;
//      }
//    }
//  }
//
//  &__group-button.gritx-btn {
//    margin: 2px 0;
//    width: 50%;
//    min-height: 40px;
//    line-height: 38px;
//    flex-grow: inherit;
//  }
//
//  &__message--right {
//    margin-right: 18px;
//  }
//
//  &__message--left {
//    margin-left: 18px;
//    font-weight: 600;
//  }
//
//  &__message--left &__message-value {
//    float: left;
//    text-align: left;
//    background: $grey;
//    color: $black;
//
//    &:before {
//      right: 100%;
//      border-right-color: $grey;
//    }
//  }
//
//  .message-loading &__message-value {
//    padding: 4px 1rem;
//
//    &::before {
//      margin-top: -22px;
//    }
//  }
//
//  &__message--right &__message-value {
//    float: right;
//    text-align: right;
//    background: $green;
//    color: $white;
//
//    &:before {
//      left: 100%;
//      border-left-color: $green;
//    }
//  }
//
//  &__start-message {
//    display: flex;
//    flex-direction: column;
//    justify-content: center;
//    align-items: center;
//    text-align: center;
//    font-size: 36px;
//    font-weight: bold;
//    height: 100%;
//  }
//
//  &__form {
//    display: flex;
//    align-items: flex-end;
//  }
//
//  &__input {
//    flex: 1 1 auto;
//    padding: 34px 20px 8px;
//    border: solid 1px $alto;
//    font-size: 24px;
//    min-height: 78px;
//    width: 100%;
//    resize: vertical;
//
//    &::placeholder {
//      font-style: italic;
//      color: $black;
//      padding-bottom: 6px;
//    }
//  }
//
//  &__btns {
//    display: flex;
//    justify-content: right;
//    width: 100%;
//  }
//
//  &__send-btn {
//    height: 78px;
//    width: 108px;
//  }
//
//  &__help-btn {
//    width: 117px;
//    margin-left: 5px;
//  }
//}
//
//@keyframes fadeNewMessage {
//  0% {
//    opacity: 0;
//    transform: translateY(1rem);
//  }
//
//  100% {
//    opacity: 1;
//    transform: translateY(0px);
//  }
//}
//
//.message-card {
//  display: flex;
//  justify-content: center;
//
//  &__wrapper {
//    background: $white;
//    border: 1px solid $alto;
//    width: 100%;
//    min-width: 250px;
//
//    @include respond-to(tablets) {
//      width: 416px;
//    }
//  }
//
//  &__title {
//    display: flex;
//    text-align: center;
//    align-items: center;
//    justify-content: center;
//    font-weight: bold;
//    color: $black;
//    background-color: $grey;
//    min-height: 75px;
//    font-size: 19px;
//
//    @include respond-to(tablets) {
//      font-size: 24px;
//    }
//  }
//
//  &__content {
//    display: flex;
//    max-height: 130px;
//    overflow: hidden;
//
//    @include respond-to(tablets) {
//      max-height: 207px;
//    }
//  }
//
//  &__picture {
//    min-width: 50%;
//    width: 50%;
//    height: 100%;
//    max-height: 100%;
//    margin-top: auto;
//  }
//
//  &__picture-loader {
//    position: relative;
//    background: transparent;
//    min-width: 50%;
//    max-height: 100%;
//    height: 107px;
//    padding: 22px;
//    margin: auto 0;
//
//    @include respond-to(tablets) {
//      height: 207px;
//      padding: 40px;
//    }
//  }
//
//  &__description {
//    font-weight: normal;
//    text-align: left;
//    white-space: pre-wrap;
//    overflow: hidden;
//    border-left: 1px solid $alto;
//    font-size: 14px;
//    padding: 8px;
//    max-height: 130px;
//    min-height: 130px;
//    line-height: 17px;
//
//    @include respond-to(tablets) {
//      font-size: 18px;
//      padding: 18px 13px 18px 13px;
//      max-height: 207px;
//      min-height: 207px;
//      line-height: 23px;
//    }
//  }
//}
//
//.hidden {
//  animation-name: hideMessage;
//  animation-duration: 1s;
//  animation-fill-mode: forwards;
//  animation-timing-function: ease;
//
//  .bot-chat__message-value {
//    max-height: 100%;
//  }
//}
//
//@keyframes hideMessage {
//  0% {
//    max-height: auto;
//    opacity: 1;
//  }
//  30% {
//    max-height: 100%;
//    opacity: 0;
//  }
//  100% {
//    max-height: 0%;
//  }
//}
