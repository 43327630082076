@import "../../components/theme/index";

.phone {
  height: 110px;

  &__label {
    font-size: 24px;
    color: $black;
  }

  &__message {
    font-size: 20px;

    &--error {
      color: $darkRed;
    }
  }

  .phone-input {
    margin-top: 10px;
    border: 1px solid $alto;

    &--focus {
      border: 1px solid transparent;
      box-shadow: none;
    }

    &--error {
      border: 1px solid $darkRed;
    }
  }

  .PhoneInputInput {
    position: relative;
    box-sizing: border-box;
    height: 60px;
    line-height: 1;
    margin: 0;
    border: none;
    font-size: 22px;
    padding-left: 70px;
    @include respond-to(tablets) {
      font-size: 24px;
    }
  }

  .PhoneInputCountry {
    position: absolute;
    z-index: 10;
    padding: 15px 10px;
  }

  .PhoneInputCountryIcon {
    width: 40px;
    height: 30px;

    &--border {
      background: transparent;
      box-shadow: none;
    }
  }

  .PhoneInputCountrySelect {
    &:focus {
      & + .PhoneInputCountryIcon--border {
        box-shadow: none;
        outline: 2px solid;
      }

      & + .PhoneInputCountryIcon + .PhoneInputCountrySelectArrow {
        color: $doveGray;
        width: 7px;
        height: 7px;
        transform: rotate(225deg);
        transition: all .3s ease;
      }

      & + .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe {
        color: $doveGray;
        border: 2px solid;
      }
    }
  }

  .PhoneInputCountrySelectArrow {
    width: 7px;
    height: 7px;
    color: $doveGray;
    opacity: 1;
    transform: rotate(45deg);
    transition: all .3s ease;
  }
}
