@use "sass:color";
@import "../theme/index";

.bot-chat {

  .article {
    margin-top: 0;

    &__heading {
      padding: 0 40px;
      font-size: 35px;
    }
  }
}