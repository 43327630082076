@import "../../components/theme/index";

.callback {
  position: relative;

  &__loader {
    max-width: 150px;
    margin: auto;
  }
}
