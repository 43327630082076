@import "../theme/index";

.dialogue-window {

  padding-top: 5px;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include respond-to(tablets) {
      padding: 0 41px 100px;
    }

    @include respond-to(largedesktop) {
      flex-wrap: nowrap;
    }
  }

  &__window,
  &__content,
  &__participants {
    margin: 5px 15px 20px;
    width: 50%;
    position: relative;

    .participants {
      margin-top: 0;

      &__heading {
        padding: 0 40px;
        font-size: 35px;
      }
    }
  }

  .article {
    margin-top: 0;
    @include respond-to(largedesktop) {
      margin-top: 0;
    }
  }

}

.dialogue-chat {

  &__help-btn {
    width: 117px!important;
    margin-left: 5px;
  }

  &__video-btn {
    width: 200px!important;
    margin-left: 5px;
  }
}

.video_loader {
  position: absolute;
  width: 100%;
  height: 100%;
}
