@import "../../components/theme/index";

.chat {
  position: relative;
  min-height:400px;

  &__title {
    display: flex;
    align-items: center;
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    color: $darkGrey;
    margin: 20px 10px 10px;
    text-transform: uppercase;

    @include respond-to(tablets) {
      margin: 0 0 44px 51px;
    }

    @include respond-to(mobile) {
      display: none;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 6px;
    border: solid 2px $alto;
    border-radius: 50%;
    padding: 7px;

    @include respond-to(mobile) {
      width: 40px;
      height: 40px;
    }
  }

  &__bot-avatar {
    width: 40px;
    height: 38px;
    fill: $red;
  }

  &__btns {
    display: flex;
    justify-content: right;
    width: 100%;
  }

  &__help-btn {
    width: 117px;
    margin-left: 5px;
  }

  &__test-btn {
    width: 70px;
  }
}
